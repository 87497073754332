/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import { etherscanLink, metamaskLink, redditLink, telegramLink, tokenName, uniswapLink } from "../Constants.js"

function DarkFooter() {
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <nav>
          <ul>
            <li>
              <a
                href={redditLink}
                target="_blank"
              >
                Reddit
              </a>
            </li>
            <li>
              <a
                href={telegramLink}
                target="_blank"
              >
                Telegram
              </a>
            </li>
            <li>
              <a
                href={uniswapLink}
                target="_blank"
              >
                Uniswap
              </a>
            </li>
            <li>
              <a
                href={etherscanLink}
                target="_blank"
              >
                Etherscan
              </a>
            </li>
            <li>
              <a
                href={metamaskLink}
                target="_blank"
              >
                MetaMask
              </a>
            </li>
          </ul>
        </nav>
        <div className="copyright" id="copyright">
          ©{new Date().getFullYear()} {tokenName}
        </div>
      </Container>
    </footer>
  );
}

export default DarkFooter;
