import React from "react";
// reactstrap components
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

import { redditLink, telegramLink } from "components/Constants.js"

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} color="info" expand="lg">
        <Container>

        <div className="navbar-translate">
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>

          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink
                  href={redditLink}
                  target="_blank"
                  id="reddit-tooltip"
                >
                  <i className="fab fa-reddit-square"></i>
                  <p className="d-lg-none d-xl-none">Reddit</p>
                </NavLink>
                <UncontrolledTooltip target="#reddit-tooltip">
                  Come chat with us on Reddit
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href={telegramLink}
                  target="_blank"
                  id="telegram-tooltip"
                >
                  <i className="fab fa-telegram-plane"></i>
                  <p className="d-lg-none d-xl-none">Telegram</p>
                </NavLink>
                <UncontrolledTooltip target="#telegram-tooltip">
                  Come chat with us on Telegram
                </UncontrolledTooltip>
              </NavItem>
            </Nav>            
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
