import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DarkFooter from "components/Footers/DarkFooter.js";
import IndexNavbar from "components/Navbars/IndexNavbar.js"
import IndexHeader from "components/Headers/IndexHeader";
import { tokenSymbol, uniswapLink, metamaskLink } from "components/Constants.js"

function IndexPage() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <IndexHeader />
        <div className="section section-buy-now">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="4">
                <h3>Invest Today</h3>
                <a class="btn btn-warning btn-lg btn-block" href={uniswapLink}  target="_blank" rel="noopener noreferrer" role="button">Buy {tokenSymbol} on Uniswap<img src={require("assets/img/uniswap.png")} alt="Uniswap" height="24" width="24" /> </a>
                <h5 className="setupWallerDescription">
                  <a class="btn btn-outline-info btn-sm btn-block" href={metamaskLink} target="_blank" rel="noopener noreferrer" role="button"><img src={require("assets/img/metamask.png")} alt="MetaMask" /> Get MetaMask Web3 Wallet</a>
                </h5>
              </Col>
            </Row>            
          </Container>
        </div>

        <DarkFooter />
      </div>
    </>
  );
}

export default IndexPage;
